import { IoClose } from "react-icons/io5";

interface DocViewerProps {
   url: string;
   onClose: () => void;
}

const DocViewer = ({ url, onClose }: DocViewerProps) => {
   if (!url) {
      return null;
      
   } else {
      return (
         <div className="flex flex-col flex-1 h-full lg:py-4 lg:pr-2">
            <div className="relative h-full bg-gray-100 lg:rounded-xl overflow-hidden">
               <button
                  className="absolute top-2 right-2 p-1 hover:bg-red-500 rounded-full"
                  onClick={() => onClose()}
               >
                  <IoClose size={18} color="white" />
               </button>
               <iframe
                  src={url}
                  title="Preview"
                  width="100%"
                  height="100%"
               ></iframe>
            </div>
         </div>
      );
   }
};

export default DocViewer;
