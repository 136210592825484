import { useState } from "react";
import UpdateTicketModal from "../modals/UpdateTicketModal";
import useContextStore from "../../stores/context";

const UpdateTicketButton = () => {
   const [isOpen, setIsOpen] = useState(false);
   const context = useContextStore();

   const closeModal = (newContext: string) => {
      setIsOpen(false);
      context.setContext(newContext);
   };

   return (
      <div className="flex items-end">
         {isOpen && <UpdateTicketModal onClose={closeModal} />}
         <button
            className="text-sm text-green-500 font-semibold px-2 py-1 bg-white rounded-3xl ring-1 ring-green-500 hover:text-white hover:bg-green-500 transition"
            onClick={() => setIsOpen(true)}
         >
            Select ticket
         </button>
      </div>
   );
};

export default UpdateTicketButton;
