import { Button } from "./Button";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {

   const handleLogout = () => {
      console.log("Logging out");
   }

   return (
      <Button onClick={() => handleLogout()} color="red" className="text-xs shadow hover:shadow-lg">
         Sign out
      </Button>
   );
};
