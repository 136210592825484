import { create } from "zustand";
import { combine } from "zustand/middleware";
import { appType, serverDomain } from "../config";
import { AppType } from "../types/types";

const defaultTempConvId = "00000000-0000-0000-0000-000000000000";

export enum EventType {
   CHANGE_LANGUAGE = "set_locale",
   SPEAK = "tts_request",
   FETCH_DB = "fetch_db_state",
   STANDARD = "query_vhector",
   SEARCH_TICKETS = "search_tickets",
   SEARCH_SOLUTION = "propose_solution",
   PROCESS_AUDIO = "process_audio",
   DELETE_CONVERSATION = "delete_conv",
   SATISFACTION = "satisfaction",
}

export enum TicketRoute {
   EDITOR = "tickets_editor",
   ITTS = "tickets_itts",
}

export type Conversation = {
   id: string;
   title: string;
   messages: any[];
   creation_date: number;
};

export type CurrentAudioSrc = {
   mediaSrc: MediaSource;
   msgId: string; // to handle the global socket state properly in each AudioButton component
};

interface Start {
   orgId: string;
   userId: string;
}

interface buildSocketMsgProps {
   eventType: EventType;
   route?: TicketRoute;
   tag?: string[];
   conversationId?: string;
   query?: string;
   key?: string;
   title?: string;
   clientName?: string;
   product?: string;
   version?: string;
   typeIncident?: string;
   messageId?: string;
   attachmentUrls?: string[];
   locale?: string;
   satisfactionRate?: number;
}

// public store functions 1
const initialState = {
   conversations: {}, // {conversation_id: {Convesation}}
   lengthConvs: 0,
   currentMsg: null,
   currentAudioSrc: {} as CurrentAudioSrc,
   spinner: "",
   submitBlocked: false,
   started: false,
};

const mutations = (setState: any, getState: any) => {
   // private store functions
   const socket = new WebSocket(`wss://dev-crm.itts.fr/neo/ws`);
   let readyState = false;
   let currentConvId = "";
   let audioEnded = false;

   socket.onopen = function (e) {
      readyState = true;
      console.log("[open] Connection established");
   };

   socket.onmessage = function (event) {
      const data = JSON.parse(event.data);
      switch (data.event_type) {
         case "message":
            setState({ currentMsg: data.message });
            setState({ spinner: "" });
            break;

         case "end_message":
            const { conversation_id, ...others } = data.message;

            const newConvs = { ...getState().conversations };
            // check if conversation exists
            if (!newConvs[conversation_id]) {
               // new conversation
               const messagesToAdd = [...newConvs[defaultTempConvId].messages];
               messagesToAdd.push({
                  ...getState().currentMsg,
                  ...others,
               });
               delete newConvs[defaultTempConvId];
               newConvs[conversation_id] = {
                  id: conversation_id,
                  title: "",
                  creation_date: others.creation_date,
                  messages: messagesToAdd,
               };
               socket.send(buildSocketMsg({ eventType: EventType.FETCH_DB }));
            } else {
               // existing conversation
               newConvs[conversation_id].messages.push({
                  ...getState().currentMsg,
                  ...others,
               });
            }

            currentConvId = conversation_id;
            setState({ submitBlocked: false });
            setState({ conversations: newConvs });
            setState({ currentMsg: null });
            break;

         case "db_state":
            const newConvers: Record<string, Conversation> = {};
            data.conversations.map((conv: any) => {
               newConvers[conv.id] = {
                  id: conv.id,
                  title: conv.title,
                  creation_date: conv.creation_date,
                  messages: conv.messages.map((msg: any) => msg),
               } as Conversation;
            });

            setState({ lengthConvs: data.conversations.length });
            setState({ conversations: newConvers });
            break;

         case "status":
            setState({ spinner: data.status });
            break;

         case "end_audio":
            audioEnded = true;
            break;

         default:
            break;
      }
   };

   socket.onclose = function (event) {
      readyState = false;
      setState({ started: false });
      if (event.wasClean) {
         console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
         );
      } else {
         // e.g. server process killed or network down
         // event.code is usually 1006 in this case
         console.log(
            "[close] Connection died, code=" +
               event.code +
               " reason=" +
               event.reason
         );
      }
   };

   socket.onerror = function (error) {
      readyState = false;
      setState({ started: false });
      console.error(`[error] ${error}`);
   };

   const buildSocketMsg: (props: buildSocketMsgProps) => string = ({
      eventType,
      route,
      tag,
      conversationId,
      query,
      key,
      title,
      clientName,
      product,
      version,
      typeIncident,
      messageId,
      attachmentUrls,
      locale,
      satisfactionRate,
   }) => {
      if (!eventType) {
         throw new Error("Missing required parameters");
      }

      const tags: string[] = [];
      let message = "";

      switch (eventType) {
         case EventType.CHANGE_LANGUAGE:
            if (!locale) {
               throw new Error("Missing required parameters");
            }
            message = JSON.stringify({
               event_type: "set_locale",
               locale,
            });
            break;

         case EventType.FETCH_DB:
            message = JSON.stringify({
               event_type: "fetch_db_state",
            });
            break;

         case EventType.DELETE_CONVERSATION:
            if (!conversationId) {
               throw new Error("Missing required parameters");
            }
            message = JSON.stringify({
               event_type: "delete_conv",
               conv_id: conversationId,
            });

            // remove conversation from store
            let newCnvs = { ...getState().conversations };
            if (conversationId === "all") {
               newCnvs = {};
               currentConvId = "";
            } else {
               delete newCnvs[conversationId];
               if (conversationId === currentConvId) {
                  currentConvId = "";
               }
            }

            setState({ conversations: newCnvs });
            setState({ lengthConvs: Object.keys(newCnvs).length });
            break;

         case EventType.SATISFACTION:
            if (!messageId || !conversationId || !satisfactionRate) {
               throw new Error("Missing conversation id");
            }
            message = JSON.stringify({
               event_type: "satisfaction",
               conversation_id: conversationId,
               message_id: messageId,
               rate: satisfactionRate,
            });
            break;

         case EventType.STANDARD:
            if (!query) {
               throw new Error("Missing query");
            }

            tags.push("doc");
            message = JSON.stringify({
               event_type: "query_vhector",
               route: "default",
               tags: tags,
               conversation_id: currentConvId,
               query,
               attachments_url: attachmentUrls,
            });

            // append user message to current conversation
            if (currentConvId === "") {
               // new conversation
               const newConvers = {
                  ...getState().conversations,
                  [defaultTempConvId]: {
                     id: defaultTempConvId,
                     title: "",
                     creation_date: Math.floor(Date.now() / 1000),
                     messages: [
                        {
                           id: "",
                           role: "user",
                           content: query,
                           creation_date: Math.floor(Date.now() / 1000),
                        },
                     ],
                  },
               };
               currentConvId = defaultTempConvId;
               setState({ conversations: newConvers });
            } else {
               // existing conversation
               const newConvers = { ...getState().conversations };
               newConvers[currentConvId].messages.push({
                  id: "",
                  role: "user",
                  content: query,
                  creation_date: Math.floor(Date.now() / 1000),
               });
               setState({ conversations: newConvers });
            }

            setState({ spinner: "Thinking" });
            setState({ submitBlocked: true });
            break;

         case EventType.SEARCH_TICKETS:
            /** TODO uncomment when ticket selector implemented   
         if (
               !title ||
               !clientName ||
               !product ||
               !query ||
               !typeIncident ||
               !route ||
               tags.length == 0
            ) {
               throw new Error("Missing context");
            }
            */
            tag?.map((t) => tags.push(t));
            message = JSON.stringify({
               event_type: "query_vhector",
               route,
               tags: tags,
               conversation_id: currentConvId,
               query,
               query_infos: {
                  /** TODO uncomment when ticket selector implemented
                  title,
                  client: clientName,
                  product,
                  version,
                  incident_type: typeIncident,
                  */
                  ticket_id: key,
               },
               attachments_url: attachmentUrls,
            });

            if (currentConvId === "") {
               // new conversation
               const newConvers = {
                  ...getState().conversations,
                  [defaultTempConvId]: {
                     id: defaultTempConvId,
                     title: "",
                     creation_date: Math.floor(Date.now() / 1000),
                     messages: [],
                  },
               };
               currentConvId = defaultTempConvId;
               setState({ conversations: newConvers });
            }
            setState({ spinner: "Thinking" });
            setState({ submitBlocked: true });
            break;

         case EventType.SEARCH_SOLUTION:
            /** TODO uncomment when ticket selector implemented   
            if (!title || !clientName || !product || !typeIncident || !query) {
               throw new Error("Missing context");
            }
            */
            tags.push("doc");
            message = JSON.stringify({
               event_type: "query_vhector",
               route: "search_solution",
               tags: tags,
               conversation_id: currentConvId,
               query,
               query_infos: {
                  /** TODO uncomment when ticket selector implemented
                  title,
                  client: clientName,
                  product,
                  version,
                  incident_type: typeIncident,
                  */
                  ticket_id: key,
               },
               attachments_url: attachmentUrls,
            });
            if (currentConvId === "") {
               // new conversation
               const newConvers = {
                  ...getState().conversations,
                  [defaultTempConvId]: {
                     id: defaultTempConvId,
                     title: "",
                     creation_date: Math.floor(Date.now() / 1000),
                     messages: [],
                  },
               };
               currentConvId = defaultTempConvId;
               setState({ conversations: newConvers });
            }
            setState({ spinner: "Thinking" });
            setState({ submitBlocked: true });
            break;

         case EventType.PROCESS_AUDIO:
            if (!attachmentUrls || attachmentUrls.length === 0) {
               throw new Error("Missing audio path");
            }
            tags.push("audio");
            message = JSON.stringify({
               event_type: "query_vhector",
               route: "process_audio",
               tags: tags,
               conversation_id: currentConvId,
               attachments_url: attachmentUrls,
            });
            if (currentConvId === "") {
               // new conversation
               const newConvers = {
                  ...getState().conversations,
                  [defaultTempConvId]: {
                     id: defaultTempConvId,
                     title: "",
                     creation_date: Math.floor(Date.now() / 1000),
                     messages: [],
                  },
               };
               currentConvId = defaultTempConvId;
               setState({ conversations: newConvers });
            }
            setState({ spinner: "Thinking" });
            setState({ submitBlocked: true });
            break;

         case EventType.SPEAK:
            if (!messageId || currentConvId === "") {
               throw new Error("Missing message id or conversation id");
            }
            tags.push("speech");
            message = JSON.stringify({
               event_type: "query_vhector",
               route: "generate_speech",
               tags: tags,
               query_infos: {
                  message_id: messageId,
               },
            });

            const mediaSrc = new MediaSource();
            let sourceOpened = false;
            mediaSrc.addEventListener("sourceopen", () => {
               if (sourceOpened) {
                  return;
               }
               sourceOpened = true;
               const sourceBuffer = mediaSrc.addSourceBuffer("audio/mpeg");
               let buffersQueue: ArrayBuffer[] = [];
               socket.addEventListener("message", (event) => {
                  const data = JSON.parse(event.data);
                  if (data.event_type === "audio") {
                     const { chunk } = data;
                     const byteChars = atob(chunk);
                     const arrayBuffer = new ArrayBuffer(byteChars.length);
                     const byteArray = new Uint8Array(arrayBuffer);
                     for (let i = 0; i < byteChars.length; i++) {
                        byteArray[i] = byteChars.charCodeAt(i);
                     }

                     buffersQueue.push(arrayBuffer);

                     if (!sourceBuffer.updating && buffersQueue.length > 0) {
                        sourceBuffer.appendBuffer(buffersQueue.shift()!);
                     }

                     setState({
                        currentAudioSrc: {
                           mediaSrc,
                           msgId: messageId,
                        },
                     });
                  }
               });
               sourceBuffer.addEventListener("updateend", () => {
                  if (!sourceBuffer.updating && buffersQueue.length > 0) {
                     sourceBuffer.appendBuffer(buffersQueue.shift()!);
                  }
                  if (
                     audioEnded &&
                     !sourceBuffer.updating &&
                     buffersQueue.length == 0
                  ) {
                     mediaSrc.endOfStream();
                  }
               });
            });
            setState({
               currentAudioSrc: {
                  mediaSrc,
                  msgId: messageId,
               },
            });
      }

      return message;
   };

   // public store functions 2
   return {
      actions: {
         start({ orgId, userId }: Start) {
            if (getState().started) {
               return;
            }

            // if socket not ready, wait for 2 seconds and try again (max 3 times)
            if (readyState === false) {
               let attempts = 0;
               const interval = setInterval(() => {
                  if (readyState === true) {
                     clearInterval(interval);
                     const message1 = {
                        event_type: "connexion",
                        org_id: orgId,
                        user_id: userId,
                        selected_agent:
                           (appType as AppType) == AppType.RH
                              ? "rh"
                              : "support",
                     };

                     socket.send(JSON.stringify(message1));
                     setState({ started: true });
                  } else {
                     attempts++;
                     if (attempts === 3) {
                        clearInterval(interval);
                     }
                  }
               }, 2000);
            } else {
               const message1 = {
                  event_type: "connexion",
                  org_id: orgId,
                  user_id: userId,
               };

               socket.send(JSON.stringify(message1));
               setState({ started: true });
            }
         },
         send: (props: buildSocketMsgProps) => {
            // if socket not ready, wait for 2 seconds and try again (max 3 times)
            if (readyState === false) {
               let attempts = 0;
               const interval = setInterval(() => {
                  if (readyState === true) {
                     clearInterval(interval);
                     socket.send(buildSocketMsg(props));
                  } else {
                     attempts++;
                     if (attempts === 3) {
                        clearInterval(interval);
                     }
                  }
               }, 2000);
            } else {
               socket.send(buildSocketMsg(props));
            }
         },

         setCurrentConv: (convId: string) => {
            currentConvId = convId;
         },

         getCurrentConv: () => {
            if (currentConvId === "") {
               // return generic conversation
               return {
                  id: "",
                  title: "",
                  messages: [],
                  creation_date: 0,
               };
            }
            return getState().conversations[currentConvId] as Conversation;
         },

         getSortedConvs: () => {
            // return a list of conversations sorted by creation date (newest first)
            const convs = Object.values(
               getState().conversations
            ) as Conversation[];
            return convs.sort((a: Conversation, b: Conversation) => {
               return b.creation_date - a.creation_date;
            });
         },

         resetAudio: () => {
            setState({ currentAudioSrc: {} });
         },

         // TODO add functions that we want to execute when an event happens: onPriority, onEndMessage, etc. These functions passed will be callbacks exectued after the internal socket logic.
      },
   };
};

// public store functions
export const useSocket = create(combine(initialState, mutations));
