import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Toaster } from "react-hot-toast";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement
);

root.render(
   <>
      <Toaster />
      <App />
   </>
);
