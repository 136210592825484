import React from "react";
import { SignOutButton } from "../buttons/SignOutButton";
import { IoClose } from "react-icons/io5";
import ClearHistoryButton from "../buttons/ClearHistoryButton";
import LanguageSelector from "../buttons/LanguageSelector";
import { AppType } from "../../types/types";
import { appType } from "../../config";

interface SettingsModalProps {
   onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ onClose }) => {
   return (
      <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
         <div className="bg-white rounded-lg flex flex-col">
            <div className="flex pl-3 items-center justify-between p-1">
               <p className="text-black text-base font-semibold">Settings</p>
               <button
                  className="p-2 hover:bg-slate-100 rounded-full"
                  onClick={() => onClose()}
               >
                  <IoClose size={18} />
               </button>
            </div>
            <div className="p-6 border-t space-y-12">
               <div className="space-y-2">
                  <h3 className="text-sm font-semibold">General</h3>
                  <div className="space-y-4">
                     <div className="flex gap-10 justify-between items-center">
                        <label className="text-sm">Choose your language</label>
                        <LanguageSelector />
                     </div>

                     {(appType as AppType) !== AppType.CRM_POWER_APP && (
                        <div className="flex justify-between items-center">
                           <label className="text-sm">
                              Clear conversations history
                           </label>
                           <ClearHistoryButton />
                        </div>
                     )}
                  </div>
               </div>

               {(appType as AppType) !== AppType.CRM_POWER_APP && (
                  <div className="space-y-2">
                     <h3 className="text-sm font-semibold">Account</h3>
                     <div className="space-y-4">
                        <div className="flex justify-between items-center">
                           <label className="text-sm">Log out</label>
                           <SignOutButton />
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default SettingsModal;
