import { create } from "zustand";

interface IFrameStore {
   url: string;
   setUrl: (url: string) => void;
}

const useIFrameStore = create<IFrameStore>((set) => ({
   url: "",
   setUrl: (url) => set({ url }),
}));

export default useIFrameStore;
