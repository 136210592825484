import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FaChevronUp } from "react-icons/fa";
import { EventType, TicketRoute } from "../../stores/socket";

const routeOptions = [
   {
      title: "Similar incidents ITTS",
      description: "Find similar incidents in local history.",
      route: TicketRoute.ITTS,
   },
   {
      title: "Similar incidents Editor",
      description:
         "Find similar incidents in Therefore Editor history.",
      route: TicketRoute.EDITOR,
   },
];

function classNames(...classes: any[]) {
   return classes.filter(Boolean).join(" ");
}

interface TicketSimilarButtonProps {
   onSubmit: (eventType: EventType, route?: TicketRoute) => void;
}

const TicketSimilarButton = ({
   onSubmit,
}: React.PropsWithChildren<TicketSimilarButtonProps>) => {
   const [selected, setSelected] = useState(routeOptions[0]);

   const onSelect = (idx: any) => {
      setSelected(idx);
      onSubmit(EventType.SEARCH_TICKETS, idx.route);
   };

   return (
      <Listbox value={selected} onChange={onSelect}>
         {({ open }) => (
            <div>
               <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
               >
                  <Listbox.Options className="z-10 mb-2 w-72 divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                     {routeOptions.map((option) => (
                        <Listbox.Option
                           key={option.title}
                           className={({ active }) =>
                              classNames(
                                 active
                                    ? "bg-primary text-white font-semibold"
                                    : "text-gray-900",
                                 "cursor-default select-none p-4 text-sm"
                              )
                           }
                           value={option}
                        >
                           {({ selected, active }) => (
                              <div className="flex flex-col">
                                 <div className="flex justify-between">
                                    <p
                                       className={
                                          selected
                                             ? "font-semibold"
                                             : "font-normal"
                                       }
                                    >
                                       {option.title}
                                    </p>
                                 </div>
                                 <p
                                    className={classNames(
                                       active
                                          ? "text-indigo-200"
                                          : "text-gray-500",
                                       "mt-2"
                                    )}
                                 >
                                    {option.description}
                                 </p>
                              </div>
                           )}
                        </Listbox.Option>
                     ))}
                  </Listbox.Options>
               </Transition>

               <div className="inline-flex">
                  <button
                     onClick={() => {
                        onSubmit(EventType.SEARCH_TICKETS, selected.route);
                     }}
                     className="inline-flex items-center gap-x-1.5 rounded-l-3xl px-2 py-1 bg-white ring-1 ring-primary hover:text-white hover:bg-primary text-sm text-primary"
                  >
                     <p className="text-sm font-semibold">{selected.title}</p>
                  </button>
                  <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-3xl px-2 py-1 bg-white ring-1 ring-primary hover:text-white hover:bg-primary text-sm text-primary">
                     <span className="sr-only">Change published status</span>
                     <FaChevronUp className="h-4 w-4" aria-hidden="true" />
                  </Listbox.Button>
               </div>
            </div>
         )}
      </Listbox>
   );
};

export default TicketSimilarButton;
