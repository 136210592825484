import { marked } from "marked";
import { AppType, MsgReceived } from "../types/types";
import { useEffect, useMemo, useState } from "react";
import useIFrameStore from "../stores/iframeStore";
import { formatDistanceToNow } from "date-fns";
import { appType } from "../config";
import { Disclosure } from "@headlessui/react";
import { FaMinus } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import {
   FaRegFaceGrinHearts,
   FaRegFaceSmile,
   FaRegFaceMeh,
   FaRegFaceAngry,
} from "react-icons/fa6";
import CopyButton from "./buttons/ButtonCopy";
import AudioButton from "./buttons/AudioButton";
import { EventType, useSocket } from "../stores/socket";

function getFilenameFromUrl(url: string) {
   // if contains ? split by ? and take the first part
   let result = url.split("?")[0];
   // if contains / split by / and take the last part
   result = result.split("/").pop() as string;
   return decodeURIComponent(result);
}

interface ChatMsgProps {
   msg: MsgReceived;
   isCurrent?: boolean;
}

const ChatMsg = ({ msg, isCurrent }: ChatMsgProps) => {
   const iframeStore = useIFrameStore();
   const [evaluation, setEvaluation] = useState(0);
   const [imgs, setImgs] = useState<any[]>([]);
   const [content, setContent] = useState<string[]>([]);
   const actions = useSocket((store) => store.actions);

   const onSelectSource = (url: string, page?: number) => {
      // TODO remove and change by links
      // if not supported extension open it in a new tab
      const extension = getFilenameFromUrl(url).split(".").pop();

      let aux = url;
      if (extension === "pdf") {
         // open pdf at the page where the source was found
         aux = `${url}#page=${page}`;
      }

      // if screen smaller than lg, open the doc in a new tab
      if (window.innerWidth < 1024) {
         window.open(aux, "_blank");
      } else {
         if (
            (extension === "png" ||
               extension === "jpg" ||
               extension === "jpeg" ||
               extension === "gif") &&
            !((appType as AppType) === AppType.CRM_POWER_APP)
         ) {
            iframeStore.setUrl(aux);
         } else {
            window.open(aux, "_blank");
         }
      }
   };

   const onEval = (rate: number) => {
      actions.send({
         eventType: EventType.SATISFACTION,
         satisfactionRate: rate,
         messageId: msg.id,
         conversationId: msg.conversation_id,
      });
      setEvaluation(rate);
   };

   const creationDate = useMemo(() => {
      if (!msg.creation_date) return "";
      const myDate = new Date(msg.creation_date * 1000);
      const shortVersion =
         myDate > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      let strDate;
      if (shortVersion) {
         strDate = formatDistanceToNow(myDate, {
            addSuffix: true,
         });
      } else {
         strDate = myDate.toLocaleDateString("fr-FR", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
         });
      }
      return strDate;
   }, [msg.creation_date]);

   useEffect(() => {
      // extract images from the markdown message
      const regex =
         /!\[(?<altText>.*)\]\s*\((?<imageURL>.+)\)|img\s*src="(?<imageURL1>[^"]*)"\s*alt="(?<altText1>[^"]*)" \/>|img\s*alt="(?<altText2>[^"]*)"\s*src="(?<imageURL2>[^"]*)" \/>/gm;

      let m;
      const newImgs = [];
      while ((m = regex.exec(msg.content)) !== null) {
         if (m.index === regex.lastIndex) regex.lastIndex++;
         newImgs.push({
            alt: m.groups!.altText ?? m.groups!.altText1 ?? m.groups!.altText2,
            src:
               m.groups!.imageURL ?? m.groups!.imageURL1 ?? m.groups!.imageURL2,
            mkd: m[0],
         });
      }

      // to avoid flashing images when rendering, we split the content into fragments
      if (isCurrent) {
         const newContent = [...content];
         if (newContent.length === 0) {
            newContent.push(msg.content);
            setContent(newContent);
            return;
         }

         if (newImgs.length === 0) {
            newContent[newContent.length - 1] = msg.content;
            setContent(newContent);
            return;
         }

         if (newImgs.length > imgs.length) {
            const lastImg = newImgs[newImgs.length - 1].mkd;
            const parts = msg.content.split(lastImg);
            newContent[newContent.length - 1] =
               newContent[newContent.length - 1] + ")";
            newContent.push(parts[1]);
            setContent(newContent);
            setImgs(newImgs);
            return;
         }
         if (newImgs.length !== 0) {
            const lastImg = newImgs[newImgs.length - 1].mkd;
            const parts = msg.content.split(lastImg);
            newContent[newContent.length - 1] = parts[1];
            setContent(newContent);
            return;
         }
      } else {
         setContent([msg.content]);
         setImgs(newImgs);
      }
   }, [msg.content]);

   return (
      <div
         className={`flex rounded-lg py-3 md:px-3 ${
            msg.role === "user" ? "justify-start" : "justify-end"
         }`}
      >
         {msg.role === "user" ? (
            <div className="flex flex-row items-center">
               <span className="hidden md:flex items-center justify-center h-10 w-10 mr-3 rounded-full bg-secondary flex-shrink-0 text-white">
                  Me
               </span>
               <div>
                  <div className="md:w-3/4 bg-white py-2 px-4 shadow rounded-xl text-sm text-pretty">
                     {content.map((c, i) => (
                        <div
                           key={i}
                           className="overflow-x-auto scrollbar-thin scrollbar-thumb-primary/20 scrollbar-track-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                           dangerouslySetInnerHTML={{
                              __html: marked.parse(c) as string,
                           }}
                        />
                     ))}
                  </div>
                  <label className="text-xs text-gray-500 mt-1">
                     {creationDate}
                  </label>
               </div>
            </div>
         ) : (
            <div className="flex flex-row-reverse items-center">
               <span className="hidden md:flex items-center justify-center h-10 w-10 rounded-full bg-secondary flex-shrink-0 text-white">
                  AI
               </span>
               <div className="md:w-4/5 bg-indigo-100 py-2 px-4 md:mr-3 shadow rounded-xl">
                  {content.map((c, i) => (
                     <div
                        key={i}
                        className="text-sm text-pretty overflow-x-auto scrollbar-thin scrollbar-thumb-primary/20 scrollbar-track-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                        dangerouslySetInnerHTML={{
                           __html: marked.parse(c) as string,
                        }}
                     />
                  ))}
                  <div
                     className={`flex flex-wrap gap-2 ${
                        (msg.sources && msg.sources.length > 0) || msg.file
                           ? "pt-4 pb-3"
                           : ""
                     }`}
                  >
                     {/**File shown */}
                     {msg.file && (
                        <div className="flex flex-col gap-1">
                           {getFilenameFromUrl(msg.file).split(".").pop() ===
                              "png" && <img src={msg.file} alt="Preview" />}
                           <div className="px-2 py-1 rounded-lg bg-slate-50 hover:bg-slate-200">
                              <button
                                 onClick={() => onSelectSource(msg.file!)}
                                 className="text-xs text-blue-500 hover:underline"
                              >
                                 Download: {getFilenameFromUrl(msg.file)}
                              </button>
                           </div>
                        </div>
                     )}
                     {msg.file && msg.sources && msg.sources.length > 0 && (
                        <div className="border-b border-gray-200 my-2 px-4"></div>
                     )}

                     {/**Sources shown */}
                     {msg.sources &&
                        msg.sources.map((source, i) => {
                           switch (source.type) {
                              case "doc":
                                 return (
                                    <div
                                       key={i}
                                       className="px-2 rounded-lg bg-slate-50 hover:bg-slate-200"
                                    >
                                       <button
                                          onClick={() =>
                                             onSelectSource(
                                                source.doc_url,
                                                source.page
                                             )
                                          }
                                          className="text-xs text-blue-500 hover:underline text-pretty"
                                       >
                                          {getFilenameFromUrl(source.doc_url)}
                                       </button>
                                    </div>
                                 );

                              case "ticket":
                                 const ticket = source.more_infos;
                                 return (
                                    <Disclosure as="div" key={i}>
                                       {({ open }) => (
                                          <div
                                             className={`rounded-lg ${
                                                open
                                                   ? "bg-white"
                                                   : "bg-indigo-50"
                                             }`}
                                          >
                                             <dt>
                                                <Disclosure.Button
                                                   className={`p-2 flex w-full items-center justify-between text-left text-black hover:bg-indigo-200 ${
                                                      open
                                                         ? "rounded-t-lg"
                                                         : "rounded-lg"
                                                   }`}
                                                >
                                                   <span className="text-sm font-semibold text-pretty">
                                                      {`(${ticket.ticket_id})`}{" "}
                                                      <span className=" font-normal">
                                                         {ticket.title}
                                                      </span>
                                                   </span>
                                                   {open && (
                                                      <FaMinus
                                                         className="h-3.5 w-3.5"
                                                         aria-hidden="true"
                                                      />
                                                   )}
                                                </Disclosure.Button>
                                             </dt>
                                             <Disclosure.Panel
                                                as="dd"
                                                className="px-2 pb-2 space-y-3"
                                             >
                                                {ticket.product && (
                                                   <div>
                                                      <p className="text-xs font-mono text-gray-700">
                                                         {`${ticket.product} ${
                                                            ticket.version &&
                                                            ticket.version !==
                                                               "version inconnue"
                                                               ? `- ${ticket.version}`
                                                               : ""
                                                         }`}
                                                      </p>
                                                   </div>
                                                )}
                                                <div>
                                                   <span className="text-sm font-semibold">
                                                      Description
                                                   </span>
                                                   <div className="text-xs text-gray-700">
                                                      <div
                                                         dangerouslySetInnerHTML={{
                                                            __html:
                                                               marked.parse(
                                                                  ticket.description
                                                               ) as string,
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                                <div>
                                                   <span className="text-sm font-semibold">
                                                      Solution
                                                   </span>
                                                   <div className="text-xs text-gray-700">
                                                      <div
                                                         dangerouslySetInnerHTML={{
                                                            __html:
                                                               marked.parse(
                                                                  ticket.solution
                                                               ) as string,
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                             </Disclosure.Panel>
                                          </div>
                                       )}
                                    </Disclosure>
                                 );
                           }
                        })}
                  </div>
                  <div className="mt-1 flex justify-between">
                     {evaluation ? (
                        <div className="text-xs p-1 rounded-full text-gray-500">
                           {evaluation === 1 ? (
                              <FaRegFaceGrinHearts className="h-3.5 w-3.5" />
                           ) : evaluation === 2 ? (
                              <FaRegFaceSmile className="h-3.5 w-3.5" />
                           ) : evaluation === 3 ? (
                              <FaRegFaceMeh className="h-3.5 w-3.5" />
                           ) : evaluation === 4 ? (
                              <FaRegFaceAngry className="h-3.5 w-3.5" />
                           ) : null}
                        </div>
                     ) : (
                        <div className="flex items-center">
                           <p className="text-xs text-gray-500">
                              Was this helpful?
                           </p>
                           <div className="flex gap-2 px-3">
                              <button
                                 className="text-xs p-1 rounded-full text-gray-500 hover:text-green-500 hover:bg-indigo-50"
                                 onClick={() => onEval(1)}
                              >
                                 <FaRegFaceGrinHearts className="h-3.5 w-3.5" />
                              </button>
                              <button
                                 className="text-xs p-1 rounded-full text-gray-500 hover:text-yellow-500 hover:bg-indigo-50"
                                 onClick={() => onEval(2)}
                              >
                                 <FaRegFaceSmile className="h-3.5 w-3.5" />
                              </button>
                              <button
                                 className="text-xs p-1 rounded-full text-gray-500 hover:text-gray-900 hover:bg-indigo-50"
                                 onClick={() => onEval(3)}
                              >
                                 <FaRegFaceMeh className="h-3.5 w-3.5" />
                              </button>
                              <button
                                 className="text-xs p-1 rounded-full text-gray-500 hover:text-red-500 hover:bg-indigo-50"
                                 onClick={() => onEval(4)}
                              >
                                 <FaRegFaceAngry className="h-3.5 w-3.5" />
                              </button>
                           </div>
                        </div>
                     )}
                     <div className="flex gap-2">
                        <CopyButton
                           data={msg.content}
                           type="button"
                           className="text-xs p-1 rounded-full text-gray-500 hover:text-gray-700 hover:bg-indigo-50"
                        >
                           <MdContentCopy className="h-5 w-5" />
                        </CopyButton>
                        {!isCurrent && (
                           <AudioButton
                              msgId={msg.id}
                              className="text-xs p-1 rounded-full text-gray-500 hover:text-gray-700 hover:bg-indigo-50"
                           />
                        )}
                     </div>
                  </div>
                  <div className="mt-2 flex gap-4 justify-end text-xs text-gray-500 font-thin">
                     <p>Tokens used: {msg.usage?.generation_token_length}</p>
                     {msg.usage?.generation_cost && (
                        <p>
                           Req cost: {msg.usage?.generation_cost.toFixed(4)} $
                        </p>
                     )}
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default ChatMsg;
