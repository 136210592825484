import toast from "react-hot-toast";
import { EventType, useSocket } from "../../stores/socket";
import { Button } from "./Button";

const ClearHistoryButton = () => {
   const actions = useSocket((store) => store.actions);

   const onSubmit = () => {
      actions.send({
         eventType: EventType.DELETE_CONVERSATION,
         conversationId: "all",
      });

      actions.send({ eventType: EventType.FETCH_DB });
      toast.success("History cleared");
   };

   return (
      <Button
         onClick={onSubmit}
         color="slate"
         className="text-xs shadow hover:shadow-lg"
      >
         Clear
      </Button>
   );
};

export default ClearHistoryButton;
