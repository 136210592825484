export type MsgReceived = {
   id: string;
   content: string;
   role: string;
   creation_date: number;
   conversation_id: string;
   sources?: Source[];
   file?: string;
   usage?: Usage;
};

export type Usage = {
   input_token_length: number;
   output_token_length: number;
   generation_token_length: number;
   input_cost: number;
   output_cost: number;
   generation_cost: number;
};

export type Source = {
   doc_url: string;
   type: string;
   more_infos: any;
   page?: number;
};

export enum AppType {
   RH = "rh",
   CRM = "crm",
   CRM_POWER_APP = "crm_power_app",
}
