import { useState } from "react";
import SettingsModal from "../modals/SettingsModal";
import { IoSettingsOutline } from "react-icons/io5";
import { AppType } from "../../types/types";
import { appType } from "../../config";

const SettingsButton = () => {
   const [isOpen, setIsOpen] = useState(false);

   return (
      <>
         {isOpen && <SettingsModal onClose={() => setIsOpen(false)} />}
         <button
            className={`flex items-center justify-center gap-1 px-3 py-1 mt-1 rounded-xl ${
               (appType as AppType) === AppType.CRM_POWER_APP
                  ? "hover:bg-white"
                  : "w-full hover:bg-slate-100"
            }`}
            onClick={() => setIsOpen(true)}
         >
            {(appType as AppType) === AppType.CRM_POWER_APP ? (
               <IoSettingsOutline size={20} />
            ) : (
               <>
                  <IoSettingsOutline size={20} />
                  <span>Settings</span>
               </>
            )}
         </button>
      </>
   );
};

export default SettingsButton;
