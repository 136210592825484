interface ConnectionStatusProps {
   readyState: string;
}

const ConnectionStatus = ({ readyState }: ConnectionStatusProps) => {
   return (
      <span
         className={`text-xs font-bold ${
            readyState === "ready" ? "text-green-600" : "text-red-600"
         }`}
      >
         {readyState}
      </span>
   );
};

export default ConnectionStatus;
