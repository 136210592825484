import { EventType } from "../../stores/socket";

interface TicketSolutionButtonProps {
   onSubmit: (eventType: EventType) => void;
}

const TicketSolutionButton = ({
   onSubmit,
}: React.PropsWithChildren<TicketSolutionButtonProps>) => {
   return (
      <div className="flex items-end">
         <button
            className="text-sm text-primary font-semibold px-2 py-1 bg-white rounded-3xl ring-1 ring-primary hover:text-white hover:bg-primary transition"
            onClick={() => onSubmit(EventType.SEARCH_SOLUTION)}
         >
            Propose a solution
         </button>
      </div>
   );
};

export default TicketSolutionButton;
