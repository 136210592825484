import { useEffect, useMemo, useState } from "react";
import { HiSpeakerWave } from "react-icons/hi2";
import { CiStop1, CiPlay1 } from "react-icons/ci";
import { EventType, useSocket } from "../../stores/socket";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

const enum AudioStatus {
   LOADING = "loading",
   PLAYING = "playing",
   STOPPED = "stopped",
   PAUSED = "paused",
}

interface AudioButtonProps {
   msgId: string;
}

const AudioButton = ({
   msgId,
   ...props
}: AudioButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
   const actions = useSocket((store) => store.actions);
   const currentAudioSrc = useSocket((store) => store.currentAudioSrc);
   const [status, setStatus] = useState<AudioStatus>(AudioStatus.STOPPED);

   const audioElement = useMemo(() => {
      const audio = new Audio();
      audio.addEventListener("ended", function () {
         setStatus(AudioStatus.STOPPED);
         actions.resetAudio();
      });
      return audio;
   }, []);

   const onStart = () => {
      if (audioElement.src) {
         audioElement.currentTime = 0;
         audioElement.play();
         setStatus(AudioStatus.PLAYING);
      } else {
         if (!currentAudioSrc.mediaSrc) {
            actions.send({
               eventType: EventType.SPEAK,
               messageId: msgId,
            });
            setStatus(AudioStatus.LOADING);
         } else {
            toast.error("There's an audio running"); 
         }
      }
   };

   const onPause = () => {
      audioElement.pause();
      setStatus(AudioStatus.PAUSED);
   };

   const onResume = () => {
      audioElement.play();
      setStatus(AudioStatus.PLAYING);
   };

   useEffect(() => {
      if (
         currentAudioSrc.mediaSrc &&
         !audioElement.src &&
         currentAudioSrc.msgId === msgId
      ) {
         audioElement.src = URL.createObjectURL(currentAudioSrc.mediaSrc);
         audioElement.play().catch((err) => {
            console.error(err);
         });
      }
   }, [currentAudioSrc]);

   useEffect(() => {
      if (!audioElement.paused) {
         setStatus(AudioStatus.PLAYING);
      }
   }, [audioElement.paused]);

   return (
      <div className="flex items-center">
         {status === AudioStatus.LOADING && (
            <BeatLoader size={6} color={"black"} />
         )}
         {status === AudioStatus.PLAYING && (
            <button onClick={onPause} {...props}>
               <CiStop1 className="h-5 w-5" />
            </button>
         )}
         {status === AudioStatus.STOPPED && (
            <button onClick={onStart} {...props}>
               <HiSpeakerWave className="h-5 w-5" />
            </button>
         )}
         {status === AudioStatus.PAUSED && (
            <button onClick={onResume} {...props}>
               <CiPlay1 className="h-5 w-5" />
            </button>
         )}
      </div>
   );
};

export default AudioButton;
