import { create } from "zustand";

interface ContextStore {
   key: string;
   setContext: (context: string) => void;
}

const useContextStore = create<ContextStore>((set) => ({
   key: "",
   setContext: (key: string) => {
      set({ key: key.trim() });
   },
}));

export default useContextStore;
