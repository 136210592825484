import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import { EventType, useSocket } from "../../stores/socket";

const languages = [
   {
      name: "English",
      code: "en",
      iaName: "anglais",
   },
   {
      name: "Spanish",
      code: "es",
      iaName: "espagnol",
   },
   {
      name: "French",
      code: "fr",
      iaName: "français",
   },
   {
      name: "German",
      code: "de",
      iaName: "allemand",
   },
];

const LanguageSelector = () => {
   const actions = useSocket((store) => store.actions);
   const [currentLanguage, setCurrentLanguage] = useState("French");

   const onChange = (language: any) => {
      actions.send({
         eventType: EventType.CHANGE_LANGUAGE,
         locale: language.iaName,
      });
      setCurrentLanguage(language.name);
   };
   return (
      <Menu as="div" className="relative inline-block text-left">
         <div>
            <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
               {currentLanguage}
               <FaChevronDown
                  className="-mr-1 h-3 w-3 text-gray-400"
                  aria-hidden="true"
               />
            </Menu.Button>
         </div>

         <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
         >
            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
               <div className="py-1">
                  {languages.map((language) => (
                     <Menu.Item key={language.code}>
                        {({ active }) => (
                           <button
                              className={`${
                                 active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700"
                              } block px-4 py-2 text-sm`}
                              onClick={() => onChange(language)}
                           >
                              {language.name}
                           </button>
                        )}
                     </Menu.Item>
                  ))}
               </div>
            </Menu.Items>
         </Transition>
      </Menu>
   );
};

export default LanguageSelector;
