import { BeatLoader } from "react-spinners";

const baseStyles = {
   solid: "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer",
};

const variantStyles = {
   solid: {
      slate: "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
      red: "bg-red-500 text-white hover:text-slate-100 hover:bg-red-600 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600",
   },
};

type VariantKey = keyof typeof variantStyles;
type ColorKey<Variant extends VariantKey> =
   keyof (typeof variantStyles)[Variant];

type ButtonProps<
   Variant extends VariantKey,
   Color extends ColorKey<Variant>
> = {
   variant?: Variant;
   color?: Color;
   loading?: boolean;
   smallLoading?: boolean;
} & Omit<React.ComponentPropsWithoutRef<"button">, "color">;

export function Button<
   Color extends ColorKey<Variant>,
   Variant extends VariantKey = "solid"
>({
   variant,
   color,
   className,
   loading,
   smallLoading,
   ...props
}: ButtonProps<Variant, Color>) {
   variant = variant ?? ("solid" as Variant);
   color = color ?? ("slate" as Color);

   className = `${baseStyles[variant]} ${variantStyles[variant][color]} ${className}`;

   return (
      <button className={className} {...props}>
         {loading ? (
            <div className={smallLoading ? "flex px-2 py-1" : ""}>
               <BeatLoader size={6} color={`${variant === "solid" ? "white" : "black" }`} />
            </div>
         ) : (
            props.children
         )}
      </button>
   );
}
