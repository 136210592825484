import { appType } from "../../config";
import { EventType, TicketRoute } from "../../stores/socket";
import { AppType } from "../../types/types";
import SummarizeButton from "./SummarizeButton";
import TicketSimilarButton from "./TicketSimilarButton";
import TicketSolutionButton from "./TicketSolutionButton";
import UpdateTicketButton from "./UpdateTicketButton";

interface ButtonsGroupProps {
   submitBlocked: boolean;
   onSubmit: (eventType: EventType, route?: TicketRoute) => void;
}

const ButtonsGroup = ({
   submitBlocked,
   onSubmit,
}: React.PropsWithChildren<ButtonsGroupProps>) => {
   if (appType as AppType === AppType.CRM) {
      return (
         <div className="flex flex-wrap gap-2 pb-3 pl-3">
            <UpdateTicketButton />
            <TicketSimilarButton
               onSubmit={onSubmit}
            />
            <TicketSolutionButton
               onSubmit={() => onSubmit(EventType.SEARCH_SOLUTION)}
            />
         </div>
      );
   }

   if (appType as AppType === AppType.CRM_POWER_APP) {
      return (
         <div className="flex flex-wrap gap-2 pb-3 pl-3">
            <TicketSimilarButton
               onSubmit={onSubmit}
            />
            <TicketSolutionButton
               onSubmit={() => onSubmit(EventType.SEARCH_SOLUTION)}
            />
         </div>
      );
   }

   if (appType as AppType === AppType.RH) {
      return (
         <div className="flex flex-wrap gap-2 pb-3 pl-3">
            <SummarizeButton
               disabled={submitBlocked}
               onSubmit={() => onSubmit(EventType.PROCESS_AUDIO)}
            />
         </div>
      );
   }

    return null;
};

export default ButtonsGroup;
