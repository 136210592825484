import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { orgId, serverDomain, userId } from "../../config";
import { BeatLoader } from "react-spinners";

interface UploadModalProps {
   onClose: (uploaded: boolean) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ onClose }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [file, setFile] = useState<File | null>(null);
   const [errorMessage, setErrorMessage] = useState<string | null>(null);

   const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
         setFile(event.target.files[0]);
      }
   };

   const onSubmit = async () => {
      if (file) {
         setLoading(true);
         const formData = new FormData();
         formData.append("org_id", orgId);
         formData.append("id", userId);
         formData.append("files", file);

         try {
            // TODO upload to blob and send url to AI
            const response = await fetch(`https://${serverDomain}/audio`, {
               method: "POST",
               body: formData,
            });

            if (response.ok) {
               onClose(true);
            } else {
               setErrorMessage("Error uploading file: " + response.statusText);
            }
         } catch (error) {
            setErrorMessage("Error uploading file");
         }
         setLoading(false);
      }
   };

   return (
      <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
         <div className="bg-white p-6 rounded-lg flex flex-col">
            <div className="mb-4">
               <button
                  className="float-right p-2 hover:bg-slate-100 rounded-full"
                  onClick={() => onClose(false)}
               >
                  <IoClose size={18} />
               </button>
               <p className="pt-2 text-black text-sm font-sans">
                  Select your meeting recording
               </p>
            </div>
            <input
               type="file"
               onChange={onFileChange}
               className="border p-2 rounded"
            />
            <button
               onClick={onSubmit}
               className="bg-primary text-white font-semibold mt-6 p-2 rounded-xl hover:bg-primary/80 transition"
            >
               {loading ? <BeatLoader size={6} color={"white"} /> : "Summarize"}
            </button>
            {errorMessage && (
               <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
            )}
         </div>
      </div>
   );
};

export default UploadModal;
