import { AppType } from "./types/types";

export const appType = "crm";
export const serverDomain = "dev-admincrm.itts.fr"; // TODO change to prod when building sidebar

// ------------------ DO NOT CHANGE ANYTHING BELOW THIS LINE ------------------

// TODO when the db will be defined we will have to move this variables to the userSettings zustand store
export const orgId = "d48b8028-b27d-ee11-8178-000d3a1e7025"; // TODO change by the organization id in our db
export const orgName = "ITTS"; // TODO change by the organization name in our db
export const userId = (appType as AppType) === AppType.RH ? "rh_agent1" : "agent_user1" ; // TODO change by the user id in our db
export const srcLogo = (appType as AppType) === AppType.CRM_POWER_APP ? "/logo.png" : `/logo.png`;
