import { useState, useRef, useEffect, useMemo } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { VscSend } from "react-icons/vsc";
import ConversationsSidebar from "./components/ConvsSidebar";
import DocViewer from "./components/DocViewer";
import { appType, orgId, userId } from "./config";
import { MsgReceived, AppType } from "./types/types";
import ButtonsGroup from "./components/buttons/ButtonsGroup";
import ConnectionStatus from "./components/SocketStatus";
import ChatMsg from "./components/ChatMsg";
import useIFrameStore from "./stores/iframeStore";
import {
   Conversation,
   EventType,
   TicketRoute,
   useSocket,
} from "./stores/socket";
import useContextStore from "./stores/context";
import toast from "react-hot-toast";
import SettingsButton from "./components/buttons/SettingsButton";
import { BeatLoader } from "react-spinners";

export default function MainPage() {
   const iframeStore = useIFrameStore();
   const context = useContextStore();
   const textAreaRef = useRef<HTMLTextAreaElement>(null);

   const submitBlocked = useSocket((store) => store.submitBlocked);
   const readyState = useSocket((store) => store.started);
   const actions = useSocket((store) => store.actions);
   const currentMsg = useSocket((store) => store.currentMsg);
   const spinner = useSocket((store) => store.spinner);
   const [conversations, setConversations] = useState<Conversation[]>([]);
   const lengthConvs = useSocket((store) => store.lengthConvs);

   useEffect(() => {
      if (lengthConvs === 0) {
         setConversations([]);
      } else {
         setConversations(actions.getSortedConvs());
      }
   }, [lengthConvs]);

   const [text, setText] = useState("");

   const socketState = useMemo(() => {
      if (readyState === false) {
         return "disconnected";
      } else {
         return "ready";
      }
   }, [readyState]);

   useEffect(() => {
      actions.start({ orgId, userId });

      // load conversations
      if ((appType as AppType) !== AppType.CRM_POWER_APP) {
         actions.send({ eventType: EventType.FETCH_DB });
      }

      // set initial context
      if ((appType as AppType) === AppType.CRM_POWER_APP) {
         const queryString = window.location.search;
         const urlParams = new URLSearchParams(queryString);
         const params = urlParams.get("data");
         if (params === null) return;
         const aux = decodeURIComponent(params);
         context.setContext(aux);
      }
   }, []);

   const onSubmit = (eventType: EventType, route?: TicketRoute) => {
      if (!readyState) {
         toast.error("Connection lost, reload the page");
         return;
      }
      if (submitBlocked) {
         toast.loading("A generation is in progress", { duration: 4000 });
         return;
      }

      try {
         let query = text;
         /** TODO uncomment when ticket selector implemented
         if (
            (eventType === EventType.SEARCH_SOLUTION ||
               eventType === EventType.SEARCH_TICKETS) &&
            context.context
         )
            query = context.context.description;
         */

         const tags = [];
         if (context.key && context.key) {
            // get the first part before -
            let tag = context.key;
            if (tag.includes("-")) {
               tag = tag.split("-")[0];
               tag = tag.trim();
               tags.push(tag);
            }
         }
         actions.send({
            eventType,
            route,
            query: query,
            tag: tags,
            /** TODO uncomment when ticket selector implemented
            title: context.key && context.key.title,
            clientName: context.key && context.key.clientName,
            product: context.key && context.key.product,
            version: context.key && context.key.productVersion,
            typeIncident: context.key && context.key.typeIncident,
            attachmentUrls: context.key && context.key.imgUrls,
            */
           key: context.key,
         });
         setText("");
         if (textAreaRef.current) {
            textAreaRef.current.value = "";
         }
      } catch (error: any) {
         toast.error(error.message);
      }
   };

   const createConversation = () => {
      if (currentMsg) {
         toast.error("Wait, a generation is in progress");
         return;
      }
      actions.setCurrentConv("");
      context.setContext("");
   };

   const changeConversation = (newConver: Conversation) => {
      if (currentMsg) {
         toast.error("Wait, a generation is in progress");
         return;
      }
      actions.send({ eventType: EventType.FETCH_DB });
      actions.setCurrentConv(newConver.id);
      context.setContext("");
   };

   const deleteConversation = (conversation: Conversation) => {
      actions.send({
         eventType: EventType.DELETE_CONVERSATION,
         conversationId: conversation.id,
      });
   };

   return (
      <div className="flex h-screen antialiased text-gray-800">
         <div className="flex flex-row h-full w-full overflow-x-hidden">
            {/* Left Sidebar (conversations) */}
            {(appType as AppType) !== AppType.CRM_POWER_APP && (
               <ConversationsSidebar
                  conversations={conversations}
                  changeConversation={changeConversation}
                  createConversation={createConversation}
                  deleteConversation={deleteConversation}
                  readyState={socketState}
               />
            )}

            {/* Main */}
            <main className="flex flex-col flex-1 h-full lg:py-2 lg:pr-2">
               <div className="relative flex flex-col items-center lg:rounded-2xl bg-gray-100 h-full px-4 pb-4 pt-1">
                  <div className="max-w-7xl flex flex-col flex-grow w-full overflow-y-auto p-1">
                     {(appType as AppType) === AppType.CRM_POWER_APP && (
                        <div className="flex justify-between">
                           <ConnectionStatus readyState={socketState} />
                           <SettingsButton />
                        </div>
                     )}
                     <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-primary/5 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full my-2">
                        {actions
                           .getCurrentConv()
                           .messages.map((message: MsgReceived, i) => (
                              <div key={i}>
                                 <ChatMsg msg={message} />
                              </div>
                           ))}
                        {currentMsg && <ChatMsg msg={currentMsg} isCurrent />}
                        {spinner && (
                           <div className="rounded-lg p-3 flex justify-end">
                              <div className="flex flex-row items-center gap-2">
                                 <p className="text-sm ml-3">{spinner}</p>
                                 <BeatLoader size={6} color={"black"} />
                              </div>
                           </div>
                        )}
                     </div>
                     <div>
                        <ButtonsGroup
                           submitBlocked={submitBlocked}
                           onSubmit={onSubmit}
                        />
                        <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 shadow-md focus-within:ring-1 focus-within:outline-none focus-within:ring-primary/20">
                           <TextareaAutosize
                              ref={textAreaRef}
                              maxRows={4}
                              minRows={1}
                              onChange={(e) => setText(e.target.value)}
                              placeholder="Type your request here"
                              className="block w-full rounded-md resize-none border-0 px-2 py-1.5 text-gray-900 focus:outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              onKeyPress={(event) => {
                                 if (event.key === "Enter" && !event.shiftKey) {
                                    if (!text) return;
                                    event.preventDefault();
                                    onSubmit(EventType.STANDARD);
                                 }
                              }}
                           />
                           <div className="ml-4">
                              <button
                                 className="flex items-center justify-center bg-primary/90 hover:bg-primary text-white px-2.5 py-2.5 flex-shrink-0 rounded-full"
                                 onClick={() => {
                                    if (!text) return;
                                    onSubmit(EventType.STANDARD);
                                 }}
                              >
                                 <VscSend size={24} />
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </main>

            {/* Right Sidebar (doc viewer) */}
            {(appType as AppType) !== AppType.CRM_POWER_APP && (
               <DocViewer
                  url={iframeStore.url}
                  onClose={() => iframeStore.setUrl("")}
               />
            )}
         </div>
      </div>
   );
}
