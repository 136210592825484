import toast from "react-hot-toast";

interface CopyLinkButtonProps {
    data: string;
}

const CopyButton = ({ data, onClick, ...props }: CopyLinkButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const onCopy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data);
            onClick && onClick(event);
            toast.success('Copied!');
        } else {
            // Fallback if clipboard API is not available
            const textarea = document.createElement('textarea');
            textarea.textContent = data;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                onClick && onClick(event);
                toast.success('Copied!');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textarea);
        }
    }

    return (
        <button
            onClick={onCopy}
            {...props}
        >
            {props.children}
        </button>
    )
}

export default CopyButton