import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

interface UploadModalProps {
  onClose: (uploaded: string) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ onClose }) => {
  const [context, setContext] = useState("");

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContext(event.target.value);
  };

  const onSubmit = async () => {
    onClose(context);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="bg-white p-6 rounded-lg flex flex-col">
        <div className="mb-4">
          <button
            className="float-right p-2 hover:bg-slate-100 rounded-full"
            onClick={() => onClose("")}
          >
            <IoClose size={18} />
          </button>
          <p className="pt-2 text-black text-sm font-sans">
            Enter the ticket key
          </p>
        </div>
        <input type="text" onChange={onChange} placeholder="Enter..." className="border p-2 rounded" />
        <button
          onClick={onSubmit}
          className="bg-primary text-white font-semibold mt-6 p-2 rounded-xl hover:bg-primary/80 transition"
        >
          Enter ticket key
        </button>
      </div>
    </div>
  );
};

export default UploadModal;
