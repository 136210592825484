import { useState } from "react";
import UploadModal from "../modals/UploadModal";

interface SummarizeButtonProps {
   disabled: boolean;
   onSubmit: () => void;
}

const SummarizeButton = ({ disabled, onSubmit }: SummarizeButtonProps) => {
   const [isOpen, setIsOpen] = useState(false);

   const closeModal = (uploaded: boolean) => {
      setIsOpen(false);

      if (uploaded) {
         onSubmit();
      }
   };

   return (
      <>
         {isOpen && <UploadModal onClose={closeModal} />}
         <button
            className="text-sm text-primary font-semibold px-2 py-1 bg-white rounded-3xl ring-1 ring-primary hover:text-white hover:bg-primary transition"
            onClick={() => {
               if (disabled) return;
               setIsOpen(true);
            }}
         >
            Summarize a meeting
         </button>
      </>
   );
};

export default SummarizeButton;
