import { GoPlusCircle } from "react-icons/go";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { AppType } from "../types/types";
import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { appType, orgName, srcLogo } from "../config";
import ConnectionStatus from "./SocketStatus";
import { isToday, isYesterday } from "date-fns";
import SettingsButton from "./buttons/SettingsButton";
import { Conversation } from "../stores/socket";

interface ConversationsSidebarProps {
   conversations: Conversation[];
   changeConversation: (conversation: Conversation) => void;
   createConversation: () => void;
   deleteConversation: (conversation: Conversation) => void;
   readyState: string;
}

const ConversationsSidebar = ({
   conversations,
   changeConversation,
   createConversation,
   deleteConversation,
   readyState,
}: ConversationsSidebarProps) => {
   // TODO get the profile information of the logged user.

   const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
   const [isConversationsVisible, setIsConverstionsVisible] = useState(() =>
      window.innerWidth > 1024 ? true : false
   );

   const description =
      (appType as AppType) === AppType.RH
         ? "An AI tool created for HHRR departments"
         : "An AI tool created for CRM systems";

   return (
      <div className="h-full flex items-center">
         {isConversationsVisible && (
            <div className="pt-6 pb-2 pl-6 h-full w-64 flex flex-col justify-between">
               <div>
                  <img src={srcLogo} alt="logo"></img>
                  <h2 className="mt-1 font-light text-xs text-center">
                     {description}
                  </h2>

                  <div className="flex justify-between mt-6 text-base items-center">
                     <span className="ml-2 font-bold">Conversations</span>
                     <button
                        className="flex items-center justify-center text-sm hover:bg-gray-100 hover:shadow rounded-xl p-1"
                        onClick={createConversation}
                     >
                        <GoPlusCircle size={22} />
                     </button>
                  </div>
               </div>

               <div className="flex flex-col flex-grow space-y-1 mt-4 -mx-2 overflow-y-auto scrollbar-thin scrollbar-thumb-primary/5 scrollbar-track-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {conversations.length === 0 && (
                     <p className="text-sm font-semibold text-center">
                        No conversations yet
                     </p>
                  )}
                  {conversations.map((conversation: Conversation, i) => {
                     const myDate = new Date(conversation.creation_date * 1000);
                     let strDate;
                     if (isToday(myDate)) {
                        strDate = "Today";
                     } else if (isYesterday(myDate)) {
                        strDate = "Yesterday";
                     } else {
                        strDate = myDate.toLocaleDateString("fr-FR", {
                           day: "numeric",
                           month: "numeric",
                           year: "numeric",
                        });
                     }

                     return (
                        <div
                           key={i}
                           className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 gap-2"
                           onMouseEnter={() => setHoveredIndex(i)}
                           onMouseLeave={() => setHoveredIndex(null)}
                           onClick={(e) => {
                              if (
                                 !(e.target as HTMLElement).closest(
                                    ".delete-button"
                                 )
                              ) {
                                 changeConversation(conversation);
                              }
                           }}
                        >
                           <div
                              className="flex items-center justify-center flex-shrink-0 h-8 w-8 bg-primary/40 rounded-full text-white hover:bg-red-600"
                              onMouseEnter={() => setHoveredIndex(i)}
                              onMouseLeave={() => setHoveredIndex(null)}
                           >
                              {hoveredIndex === i ? (
                                 <button
                                    className="delete-button"
                                    onClick={() => {
                                       deleteConversation(conversation);
                                    }}
                                 >
                                    <MdDeleteOutline size={22} />
                                 </button>
                              ) : (
                                 <>
                                    {typeof conversation.title === "string" &&
                                    conversation.title.length > 0
                                       ? conversation.title.charAt(0)
                                       : ""}
                                 </>
                              )}
                           </div>
                           <div className="flex flex-col whitespace-nowrap truncate">
                              <p
                                 className={`text-sm font-semibold truncate overflow-hidden ${
                                    hoveredIndex === i ? "overflow-visible" : ""
                                 }`}
                                 style={{
                                    transition: "margin-left 3s ease-in-out",
                                    marginLeft:
                                       hoveredIndex === i ? "-100%" : "0",
                                 }}
                              >
                                 {conversation.title}
                              </p>
                              <label className="text-xs text-gray-500 truncate">
                                 {strDate}
                              </label>
                           </div>
                        </div>
                     );
                  })}
               </div>
               <div className="border-t">
                  <div className="mt-2 flex text-sm justify-between">
                     <p>Session state:</p>
                  </div>
                  <div>
                     <p className="font-sans text-xs text-gray-400">
                        Connection: <ConnectionStatus readyState={readyState} />
                     </p>
                     {/*  TODO uncomment
                     <p className="font-sans text-xs text-gray-400">
                        Name:{" "}
                        <span className=" text-gray-600 font-semibold">
                           {accounts.length > 0
                              ? accounts[0].name
                              : "no user logged in"}
                        </span>
                     </p>
                     <p className="font-sans text-xs text-gray-400">
                        Username:{" "}
                        <span className=" text-gray-600 font-semibold">
                           {accounts.length > 0
                              ? accounts[0].username
                              : "no user logged in"}
                        </span>
                     </p>
                      */}
                     <p className="font-sans text-xs text-gray-400">
                        Organization:{" "}
                        <span className=" text-gray-600 font-semibold">
                           {orgName}
                        </span>
                     </p>
                  </div>
                  <div className="border-t mt-3" />
                  <SettingsButton />
               </div>
            </div>
         )}
         <div
            className={`${
               isConversationsVisible ? "ml-3 mr-1" : "ml-1"
            } flex flex-grow h-full bg-white hover:bg-gray-50 rounded-full cursor-pointer`}
            onClick={() => {
               setIsConverstionsVisible((prev) => !prev);
            }}
         >
            <button className="text-sm">
               {isConversationsVisible ? (
                  <IoIosArrowBack size={14} color="gray" />
               ) : (
                  <IoIosArrowForward size={14} color="gray" />
               )}
            </button>
         </div>
      </div>
   );
};

export default ConversationsSidebar;
